<template>
  <div
    v-if="
      question.question == '' &&
      question.answer == null &&
      question.explain == '' &&
      question.image == ''
    "
    class="question_show"
  >
    <div class="quiz_title_wrapper">
      <QuizzTitle :width="292" :height="223" />
    </div>
    <QuizzError @refreshGame="refreshGame" />
  </div>
  <div v-else>
    <div class="quiz_title_wrapper">
      <QuizzTitle :width="292" :height="223" />
    </div>
    <div class="question_show">
      <img
        :src="'/question_images/' + question.image"
        alt="Image pour la question"
        width="884"
        height="390"
        loading="lazy"
        class="question_image"
      />
      <div class="time_question_wrapper">
        <div class="timer_wrapper" style="width: 80px">
          <NewTimerCountdown
            v-if="timer > 0"
            :timer_prop="timer"
            :timer_radius="40"
            :label_font_size="35"
            :label_line_height="35"
          />
        </div>
        <div class="question_wrapper">
          <div class="question_count">Question n°{{ question_count }}</div>
          <h4>
            {{ question.question }}
          </h4>
          <div v-if="timer > 0" class="answers">
            <div class="answer true">Vrai</div>
            <span class="or">OU</span>
            <div class="answer false">Faux</div>
          </div>
          <div v-else class="result">
            <h5 :class="'answer_title ' + (question.answer ? 'true' : 'false')">
              C'est {{ question.answer ? "vrai" : "faux" }} !
            </h5>
            <button
              type="button"
              @click="launchNextQuestion"
              v-if="!quizz_ended"
            >
              Question suivante
              <img
                src="@/assets/icons/arrow_next_plain.svg"
                alt="Icone flèche suivant"
                width="25"
                height="22"
              />
            </button>
            <button type="button" @click="showResults" v-else>
              Voir le classement final
              <img
                src="@/assets/icons/arrow_next_plain.svg"
                alt="Icone flèche suivant"
                width="25"
                height="22"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="users_list.length > 0" class="user_top">
      <img
        src="@/assets/icons/cup.svg"
        alt="Icone coupe"
        width="59"
        height="39"
      />
      <h6>TOP 3</h6>
      <div class="users">
        <div v-for="(user, id) in users_list" :key="id" class="user">
          <strong> {{ user.name }}</strong> {{ user.score }} pts
          <span> - {{ id + 1 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QuizzError from "./QuizzError.vue";
import QuizzTitle from "../utils/QuizzTitle.vue";
import NewTimerCountdown from "../utils/NewTimerCountdown.vue";
export default {
  name: "QuestionShow",
  components: {
    QuizzError,
    QuizzTitle,
    NewTimerCountdown,
  },
  props: [
    "question_prop",
    "timer_prop",
    "question_count_prop",
    "quizz_ended_props",
    "top_users",
  ],
  data() {
    return {
      timer: this.timer_prop,
      question_count: this.question_count_prop,
      question: this.question_prop,
      quizz_ended: this.quizz_ended_props,
      users_list: this.top_users,
    };
  },
  methods: {
    launchNextQuestion() {
      this.$emit("launchNextQuestion");
    },
    showResults() {
      this.$emit("showResults");
    },
    refreshGame() {
      this.$emit("refreshGame");
    },
  },
  watch: {
    question_prop: {
      handler(newQuestion) {
        this.question = newQuestion;
      },
      deep: true,
    },
    timer_prop: {
      handler(newTimer) {
        this.timer = newTimer;
      },
    },
    question_count_prop: {
      handler(newQuestionCount) {
        this.question_count = newQuestionCount;
      },
    },
    quizz_ended_props: {
      handler(newQuizzEnded) {
        this.quizz_ended = newQuizzEnded;
      },
    },
    top_users: {
      handler(newTop) {
        this.users_list = newTop;
      },
      deep: true,
    },
  },
};
</script> 
<style scoped>
.quiz_title_wrapper {
  text-align: left;
  margin: 62px 0 0 79px;
}

.question_show {
  margin-top: 60px;
}
.question_show .question_image {
  margin-top: -177px;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}
.question_show .time_question_wrapper {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 40px;
}

.question_show .timer_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.question_show .time_question_wrapper .question_wrapper {
  padding-top: 20px;
  flex-direction: column;
  display: flex;
  gap: 25px;
  align-items: flex-start;
  justify-content: flex-start;
}

.question_show .time_question_wrapper .question_count {
  color: #66c9f3;
  /* font */
  font-family: "Quicksand";
  font-size: 30pt;
  font-weight: bold;
  line-height: 30pt;
}

.question_show h4 {
  margin: 0;
  max-width: 885px;
  text-align: left;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: bold;
  font-size: 40pt;
  line-height: 40pt;
}

.question_show .answers {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 40px;
  margin-top: 25px;
}
.question_show .answers .answer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 15px;
  padding: 28px 40px;
  border-radius: 25px;
  box-shadow: 2px 6px 8px 1px rgba(0, 0, 0, 0.25);
  /* font */
  font-family: "Quicksand";
  text-transform: uppercase;
  font-size: 30pt;
  font-weight: bold;
  line-height: 30pt;
}
.question_show .answers .answer.true {
  background-color: #80cd3c;
}
.question_show .answers .answer.false {
  background-color: #f22612;
}

.question_show .answers .or {
  color: white;
  /* font */
  font-family: "Quicksand";
  font-size: 30pt;
  font-weight: bold;
  line-height: 30pt;
}

.question_show .result {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 50px;
}

.question_show .result .answer_title {
  margin: 0;
  color: #66c9f3;
  /* font */
  font-family: "Quicksand";
  font-size: 70pt;
  font-weight: bold;
  line-height: 70pt;
}

.question_show .result .answer_title.true {
  color: #64d302;
}

.question_show .result .answer_title.false {
  color: #f22612;
}

.question_show .result button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  padding: 15px 20px;
  border: none;
  border-radius: 20px;
  background-color: #f2b805;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  /* font */
  font-family: "Quicksand";
  font-size: 20pt;
  font-weight: bold;
  line-height: 20pt;
}

.user_top {
  position: absolute;
  right: 50px;
  bottom: 120px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
}

.user_top h6 {
  margin: 0;
  color: #f2b805;
  /* font */
  font-family: "Quicksand";
  font-size: 36pt;
  font-weight: bold;
  line-height: 36pt;
}

.user_top .users {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
}

.user_top .users .user {
  color: #fff;
  /* font */
  font-family: "Quicksand";
  font-size: 30pt;
  font-weight: lighter;
  line-height: 30pt;
}

.user_top .users .user span {
  color: #f2b805;
  /* font */
  font-weight: bold;
}
</style>