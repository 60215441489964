<template>
  <div>
    <div class="home_screen_container">
      <img
        src="@/assets/logo_jeu_PCC.svg"
        alt="logo Le grand quiz PCC"
        width="450"
        height="357.02"
      />
      <div>
        <button type="button" @click="startASession">C'est parti !</button>
      </div>
    </div>
    <video
      id="background-video"
      autoplay
      loop
      muted
      poster="@/assets/video_home/PCC_video_home_screen.jpg"
    >
      <source src="@/assets/video_home/PCC_video_home.mp4" type="video/mp4" />
    </video>
    <div id="background-video-cover"></div>
  </div>
</template>
<script>
export default {
  name: "HomeScreen",
  methods: {
    startASession() {
      this.$emit("startSession");
    },
  },
};
</script>
<style scoped>
#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
}
#background-video-cover {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: rgba(0, 0, 0, 0.15);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 20;
}

.home_screen_container {
  text-align: center;
  position: absolute;
  top: calc(50%);
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 100;
}
.home_screen_container img {
  margin-bottom: 100px;
}
.home_screen_container button {
  cursor: pointer;
  padding: 17px 49px;
  border: none;
  color: #fff;
  background-color: #f2b811;
  border-radius: 20px;
  /* font */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 30pt;
  line-height: 30pt;
}
</style>