<template>
  <div class="users_home">
    <div class="waiting_screen" v-if="game_started && user_name == ''">
      <QuizzTitle :width="160" :height="127" />
      <WaitingScreen
        sentence="Une partie est déjà en cours, vous pouvez revenir pour la prochaine !"
      />
    </div>
    <template v-else>
      <div v-if="session_started">
        <div v-if="!awaiting_launch">
          <div class="waiting_screen" v-if="max_users_reach">
            <QuizzTitle :width="160" :height="127" />
            <WaitingScreen
              sentence="Le nombre maximum de joueurs pour cette partie est atteint. Vous
              pouvez revenir pour la prochaine !"
            />
          </div>
          <template v-else>
            <UsersRegister
              :user_name_taked="user_name_taked"
              @newUser="newUser"
            />
          </template>
        </div>
        <div v-else>
          <div v-if="game_started">
            <div v-if="show_result">
              <UsersRank
                :session_rank="session_leaderboard"
                :general_rank="general_leaderboard"
                :user_id="user_id"
              />
            </div>
            <template v-else>
              <div class="on_question_title_wrapper" v-if="!user_on_reconnect">
                <QuizzTitle :width="116" :height="93" />
                <QuestionCount
                  :question_count="question_count"
                  :total_question_count="total_question_count"
                />
              </div>
              <div v-if="on_question_launch" class="question_launch">
                <div class="timer_wrapper">
                  <NewTimerCountdown
                    :timer_prop="timer"
                    :timer_radius="50"
                    :label_font_size="50"
                    :label_line_height="40"
                  />
                </div>
              </div>
              <template v-else>
                <div class="waiting_screen" v-if="user_on_reconnect">
                  <QuizzTitle :width="160" :height="127" />
                  <WaitingScreen
                    sentence="Vous avez rencontré un problème pendant le quiz ? <br/>Pas d'inquiétude, vous pourrez de nouveau jouer dès la prochaine question !"
                  />
                </div>
                <template v-else>
                  <QuestionShow
                    :question_prop="question"
                    :timer_prop="timer"
                    :user_answer_prop="user_answer"
                    :question_count_prop="question_count"
                    @userResponse="saveUserResponse"
                  />
                </template>
              </template>
            </template>
          </div>
          <div class="waiting_screen" v-else>
            <QuizzTitle :width="160" :height="127" />
            <WaitingScreen
              sentence="Le jeu est guidé par l’animateur. Une fois le temps écoulé, seul l’animateur aura la possibilité de passer à la question suivante. <br/> La partie va bientôt démarrer ! Tiens-toi prêt !"
            />
          </div>
        </div>
      </div>
      <div v-else class="waiting_screen">
        <QuizzTitle :width="160" :height="127" />
        <WaitingScreen
          sentence="L'inscription à la prochaine session n'est pas encore ouverte."
        />
      </div>
    </template>
    <div
      v-if="
        user_id != null &&
        timer <= 0 &&
        !show_result &&
        !on_question_launch &&
        question.question &&
        question.answer == user_answer
      "
      class="background_gradient green"
    ></div>
    <div
      v-else-if="
        user_id != null &&
        timer <= 0 &&
        !show_result &&
        !on_question_launch &&
        question.question &&
        question.answer != user_answer
      "
      class="background_gradient red"
    ></div>
    <div v-else class="background_gradient default"></div>
    <UsersFooter />
  </div>
</template>
<script>
import QuizzTitle from "../components/utils/QuizzTitle.vue";
import QuestionCount from "../components/users/QuestionCount.vue";
import NewTimerCountdown from "../components/utils/NewTimerCountdown.vue";
import UsersRegister from "../components/users/UsersRegister.vue";
import QuestionShow from "../components/users/QuestionShow.vue";
import WaitingScreen from "../components/utils/WaitingScreen.vue";
import UsersRank from "../components/users/UsersRank.vue";
import UsersFooter from "../components/users/UsersFooter.vue";
//importing the socket.io we installed
import io from "socket.io-client";
export default {
  name: "UsersHome",
  components: {
    QuizzTitle,
    QuestionCount,
    NewTimerCountdown,
    UsersRegister,
    QuestionShow,
    WaitingScreen,
    UsersRank,
    UsersFooter,
  },
  data() {
    return {
      socket: {},
      session_started: false,
      awaiting_launch: false,
      user_name: "",
      user_name_taked: false,
      session_id: null,
      user_id: null,
      user_on_reconnect: false,
      max_users_reach: false,
      user_answer: null,
      game_started: false,
      timer: 0,
      timer_interval: null,
      question_count: 0,
      total_question_count: 0,
      on_question_launch: false,
      question: {
        question: "",
        answer: null,
        explain: "",
        image: "",
      },
      quizz_ended: false,
      show_result: false,
      session_leaderboard: [],
      general_leaderboard: [],
    };
  },
  created() {
    //connecting to our host
    this.socket = io(process.env.VUE_APP_SOCKET_URL);
  },
  mounted() {
    // Refresh view when server restart
    this.socket.on("refresh-all", () => {
      this.reset();
    });
    // Refresh view when server restart
    this.socket.on("reset-all", () => {
      this.reset();
    });
    //Check if a new session started
    this.socket.on("has_session_started", (data) => {
      this.session_started = data;
    });
    //Check if a game as started
    this.socket.on("current_session_started", (data) => {
      this.game_started = data;
    });
    //Check if a users count max is reach
    this.socket.on("max-users-reach", (data) => {
      this.max_users_reach = data;
    });
    //Get total question foreach sessions
    this.socket.on("total-question-count", (data) => {
      this.total_question_count = data;
    });
    //Update user id with db id
    this.socket.on("user-update-id", (data) => {
      if (this.user_id == data.old_user_id) {
        this.user_id = data.new_user_id;
      }
      if (data.save_locale_storage) {
        localStorage.setItem(
          "currentUser",
          JSON.stringify({
            user_id: this.user_id,
            user_name: this.user_name,
            session_id: this.session_id,
          })
        );
      }
    });
    //Launch a new question / Show pre-question timer
    this.socket.on("launch_new_question", (data) => {
      clearInterval(this.timer_interval);
      this.game_started = true;
      this.question_count++;
      this.user_answer = null;
      this.user_on_reconnect = false;
      this.on_question_launch = true;
      let self = this;
      this.timer_interval = setInterval(() => {
        self.timer -= 1;
      }, 1000);
      this.timer = data;
    });
    //Show new question to users
    this.socket.on("current_question_started", (data) => {
      clearInterval(this.timer_interval);
      let self = this;
      this.timer_interval = setInterval(() => {
        self.timer -= 1;
      }, 1000);
      this.on_question_launch = false;
      this.timer = data.timer;
      this.question = data;
    });

    //Question ended
    this.socket.on("question_ended", () => {
      this.timer = 0;
      clearInterval(this.timer_interval);
    });

    //Quizz ended
    this.socket.on("quizz-ended", (data) => {
      this.quizz_ended = true;
      this.general_leaderboard = data.general_leaderboard;
      this.session_leaderboard = data.session_leaderboard;
    });

    //Show result to users
    this.socket.on("show_results", () => {
      this.show_result = true;
    });

    //check to reconnect an user
    if (localStorage.getItem("currentUser") !== null) {
      let currentUser = JSON.parse(localStorage.getItem("currentUser"));
      if (currentUser.session_id == null) {
        localStorage.removeItem("currentUser");
      } else {
        this.socket.emit("reconnect-user", currentUser, (response) => {
          //if user session is finish, delete localStorage
          if (response.session_finish) {
            localStorage.removeItem("currentUser");
          } else {
            console.log(response);
            this.awaiting_launch = true;
            this.user_id = currentUser.user_id;
            this.user_name = currentUser.user_name;
            this.session_id = currentUser.session_id;
            this.question_count = response.question_count;
            this.user_on_reconnect = true;
          }
        });
      }
    }
  },
  methods: {
    newUser(name) {
      this.user_name_taked = false;
      if (name != "") {
        this.user_name = name;
        this.socket.emit("new-user", this.user_name, (response) => {
          if (response.error) {
            this.user_name_taked = true;
          } else {
            this.user_id = response.user_id;
            this.session_id = response.session_id;
            this.awaiting_launch = true;
          }
        });
      }
    },
    saveUserResponse(value) {
      this.user_answer = value;
      this.socket.emit("user-response", {
        user_id: this.user_id,
        user_response: value,
      });
    },
    reset() {
      this.session_started = false;
      this.awaiting_launch = false;
      this.user_name = "";
      this.user_name_taked = false;
      this.user_id = null;
      this.session_id = null;
      this.user_answer = null;
      this.max_users_reach = false;
      this.game_started = false;
      this.timer = 0;
      clearInterval(this.timer_interval);
      this.question = {
        question: "",
        answer: null,
        explain: "",
        image: "",
      };
      this.question_count = 0;
      this.total_question_count = 0;
      this.on_question_launch = false;
      this.quizz_ended = false;
      this.show_result = false;
      this.session_leaderboard = [];
      this.general_leaderboard = [];
      localStorage.removeItem("currentUser");
      this.user_on_reconnect = false;
    },
  },
};
</script>
<style scoped>
.background_gradient {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.background_gradient.default {
  background: rgb(0, 83, 142);
  background: radial-gradient(
    circle,
    rgba(0, 83, 142, 1) 0%,
    rgba(8, 64, 103, 1) 100%
  );
}

.background_gradient.green {
  background: rgb(133, 196, 67);
  background: radial-gradient(
    circle,
    rgba(133, 196, 67, 1) 0%,
    rgba(93, 156, 66, 1) 100%
  );
}

.background_gradient.red {
  background: rgb(238, 42, 36, 1);
  background: radial-gradient(
    circle,
    rgba(240, 122, 34, 1) 0%,
    rgba(238, 42, 36, 1) 100%
  );
}

.users_home {
  padding: 0 30px;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  margin: 0;
  display: grid;
  grid-template-rows: 1fr auto;
}

.waiting_screen {
  margin-top: 112px;
}

.on_question_title_wrapper {
  margin-top: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.timer_wrapper {
  margin-top: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
<style>
.waiting_screen .quizz_title {
  margin-bottom: 40px;
}
</style>