<template>
  <div class="question_show">
    <img
      :src="'/question_images/' + question.image"
      alt="Icone pour la question"
      width="225"
      height="250"
      loading="lazy"
      class="question_image"
    />
    <h3>Question n°{{ question_count }}</h3>
    <h4>
      {{ question.question }}
    </h4>
    <div class="question_wrapper" v-if="timer > 0">
      <div class="timer_wrapper" style="width: 60px">
        <NewTimerCountdown
          v-if="timer > 0"
          :timer_prop="timer"
          :timer_radius="30"
          :label_font_size="24"
          :label_line_height="24"
        />
      </div>
      <div class="answers">
        <button
          :class="
            'answer true ' +
            (user_answer != null && user_answer ? 'selected' : '')
          "
          @click="saveUserResponse(true)"
        >
          <img
            src="@/assets/icons/thumb.svg"
            alt="Icone de pouce en l'air"
            width="74"
            height="71"
          />
          Vrai
        </button>
        <button
          :class="
            'answer false ' +
            (user_answer != null && !user_answer ? 'selected' : '')
          "
          @click="saveUserResponse(false)"
        >
          <img
            src="@/assets/icons/thumb.svg"
            alt="Icone de pouce en l'air"
            width="74"
            height="71"
          />
          Faux
        </button>
      </div>
    </div>
    <div v-else class="response_wrapper">
      <h5 v-if="question.answer == user_answer">Bonne réponse !</h5>
      <h5 v-else>Mauvaise réponse !</h5>
    </div>
  </div>
</template>
<script>
import NewTimerCountdown from "../utils/NewTimerCountdown.vue";
export default {
  name: "QuestionShow",
  components: {
    NewTimerCountdown,
  },
  props: [
    "question_prop",
    "timer_prop",
    "user_answer_prop",
    "question_count_prop",
  ],
  data() {
    return {
      timer: this.timer_prop,
      question: this.question_prop,
      user_answer: this.user_answer_prop,
      question_count: this.question_count_prop,
    };
  },
  methods: {
    saveUserResponse(value) {
      this.$emit("userResponse", value);
    },
  },
  watch: {
    question_prop: {
      handler(newQuestion) {
        this.question = newQuestion;
      },
      deep: true,
    },
    timer_prop: {
      handler(newTimer) {
        this.timer = newTimer;
      },
    },
    user_answer_prop: {
      handler(newUserAnswer) {
        this.user_answer = newUserAnswer;
      },
    },
    question_count_prop: {
      handler(newQuestionCount) {
        this.question_count = newQuestionCount;
      },
    },
  },
};
</script> 
<style scoped>
.question_show {
  margin-top: 30px;
}
.question_show .question_image {
  width: auto;
  max-width: 100%;
  object-fit: contain;
}
.question_show h3 {
  margin: 10px 0;
  text-align: center;
  color: #fff;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: bold;
  font-size: 2em;
  line-height: 2em;
}
.question_show h4 {
  margin: 0 5px 20px;
  max-width: 1515px;
  text-align: center;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-size: 1.35em;
  line-height: 1.35em;
}
.question_show .timer_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 70px auto;
}
.question_show .answers {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
}
.question_show .answers .answer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 15px;
  padding: 24px 30px 20px 30px;
  border-radius: 20px;
  border: 0;
  box-shadow: 2px 6px 8px 1px rgba(0, 0, 0, 0.25);
  color: #fff;
  text-transform: uppercase;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5em;
  line-height: 38px;
}
.question_show .answers .answer img {
  max-width: 100%;
  height: auto;
}
.question_show .answers .answer.true {
  background-color: #80cd3c;
}
.question_show .answers .answer.true img {
  transform: rotate(-20.04deg);
}
.question_show .answers .answer.false {
  background-color: #f22612;
}
.question_show .answers .answer.false img {
  transform: rotate(135deg);
}
.question_show .answers .answer.selected {
  box-shadow: inset 2px 6px 15px 1px rgba(0, 0, 0, 0.7);
}
.question_show .answers .answer.selected.true {
  background-color: #759f2e;
}
.question_show .answers .answer.selected.false {
  background-color: #b60e0e;
}

.question_show .response_wrapper {
  margin-top: 50px;
}

.question_show .response_wrapper h5 {
  margin: 0;
  color: #fff;
  text-align: center;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: bold;
  font-size: 2.3em;
  line-height: 1.8em;
}
</style>