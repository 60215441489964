<template>
  <div class="question_count">{{ count }}<span class="lightblue">/{{ total }}</span></div>
</template>
<script>
export default {
  name: "QuestionCount",
  props: ["question_count", "total_question_count"],
  data() {
    return {
      count: this.question_count,
      total: this.total_question_count,
    };
  },
  watch: {
    question_count: {
      handler(newCount) {
        this.count = newCount;
      },
    },
    total_question_count: {
      handler(newTotalCount) {
        this.total = newTotalCount;
      },
    },
  },
};
</script>
<style scoped>
.question_count {
  box-sizing: border-box;
  position: absolute;
  top: 62px;
  right: 50px;
  color: #fff;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 44pt;
  line-height: 44pt;
}
.question_count .lightblue {
  color: #66c9f3;
}
</style>