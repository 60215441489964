<template>
  <div id="app">
    <router-view :key="$route.path" />
  </div>
</template>
<script>
export default {
  name: "app",
};
</script>

<style>
body {
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 18px;
  color: #fff;
}

small {
  font-size: 0.5em!important;
}
</style>
