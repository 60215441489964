<template>
  <div class="ranks">
    <div class="lottie_wrapper">
      <LottieAnimation
        path="./lottie/fish_bubble.json"
        :loop="true"
        :autoPlay="true"
        :speed="1"
        :width="70"
        :height="140"
      />
    </div>
    <h3>Bravo !</h3>
    <div class="session_rank">
      <h5>Classement de la partie</h5>
      <div class="players">
        <div
          v-for="(user, id) in session_rank"
          :key="id"
          :class="'player ' + (user.id == user_id ? 'current_player' : '')"
        >
          <div class="position">{{ id < 10 ? "0" : "" }}{{ id + 1 }}</div>
          <div class="name">
            <strong> {{ user.name }}</strong> - {{ user.score }} pts
          </div>
        </div>
      </div>
    </div>
    <div class="general_rank">
      <button v-if="!show_general_rank" @click="showGeneralRank">
        Voir le classement général
      </button>
      <template v-else>
        <h5>Classement général</h5>
        <div class="players">
          <div
            v-for="(user, id) in general_rank"
            :key="id"
            :class="'player ' + (user.id == user_id ? 'current_player' : '')"
          >
            <div class="position">{{ id < 10 ? "0" : "" }}{{ id + 1 }}</div>
            <div class="name">
              <strong> {{ user.name }}</strong> - {{ user.score }} pts
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
export default {
  name: "UsersRank",
  components: {
    LottieAnimation,
  },

  props: ["session_rank", "general_rank", "user_id"],
  data() {
    return {
      show_general_rank: false,
    };
  },
  methods: {
    showGeneralRank() {
      this.show_general_rank = true;
    },
  },
};
</script>
<style scoped>
.ranks {
  margin-top: 32px;
}

.ranks .lottie_wrapper > div {
  margin: auto !important;
}

.ranks h3 {
  margin-top: 10px;
  margin-bottom: 40px;
  text-transform: uppercase;
  /* font */
  font-family: "Quicksand";
  font-weight: 700;
  font-size: 1.875em;
  line-height: 38px;
}

.ranks .general_rank {
  margin-top: 40px;
}

.ranks .session_rank h5,
.ranks .general_rank h5 {
  margin-top: 0;
  margin-bottom: 15px;
  text-align: left;
  text-transform: uppercase;
  /* font */
  font-family: "Quicksand";
  font-weight: 700;
  font-size: 1em;
  line-height: 24px;
}

.ranks .general_rank button {
  padding: 11px 22.5px;
  background-color: #e6b319;
  color: #fff;
  border-radius: 16px;
  border: 0;
  /* font */
  font-family: "Poppins";
  font-weight: 600;
  font-size: 0.875em;
  line-height: 28px;
}

.ranks .players {
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: flex-start;
  row-gap: 14px;
}

.ranks .players .player {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 60px;
}
.ranks .players .player .position {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #000000;
  border-radius: 50%;
  /* font */
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.25em;
  line-height: 30px;
}
.ranks .players .player .name {
  /* font */
  font-family: "Quicksand";
  font-weight: 500;
  font-size: 1.25em;
  line-height: 24px;
}
.ranks .players .player.current_player .position {
  color: #fff;
  background-color: #f2b811;
}
.ranks .players .player.current_player .name {
  color: #f2b811;
}
</style>