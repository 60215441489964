<template>
  <div class="waiting_screen">
    <div class="quiz_title_wrapper">
      <QuizzTitle :width="292" :height="223" />
    </div>
    <div class="waiting_screen_wrapper">
      <div class="users_col">
        <h2>Inscrits :</h2>
        <div class="users">
          <div v-for="(user, id) in users" :key="id" class="user">
            <span>{{ user.name }}</span>
          </div>
        </div>
        <button type="button" @click="startAGame" v-if="users.length > 0">
          C'est parti !
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import QuizzTitle from "../utils/QuizzTitle.vue";
export default {
  name: "WaitingScreen",
  components: {
    QuizzTitle,
  },
  props: ["user_list"],
  data() {
    return {
      users: this.user_list,
      game_url: process.env.VUE_APP_GAME_URL,
    };
  },
  methods: {
    startAGame() {
      this.$emit("startGame");
    },
  },
  watch: {
    user_list: {
      handler(newUsers) {
        this.users = newUsers;
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.waiting_screen {
  z-index: 20;
  margin-top: 80px;
}

.waiting_screen .quiz_title_wrapper {
  text-align: left;
  margin: 62px 0 0 79px;
}

.waiting_screen_wrapper {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.waiting_screen_wrapper .users_col {
  width: 1280px;
  text-align: start;
}

h2 {
  margin-top: 0;
  max-width: 280px;
  height: 55px;
  text-transform: uppercase;
  /* font */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 25pt;
  line-height: 25pt;
}
.waiting_screen_wrapper .users_col h2 {
  margin-bottom: 50px;
}

.users {
  padding: 0 50px;
  margin-bottom: 61px;
  height: 333.5px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: space-between;
  row-gap: 60px;
  column-gap: 60px;
}

.users .user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 25px;
  width: 350px;
  color: #66c9f3;
  /* font */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 25pt;
  line-height: 25pt;
}

button {
  cursor: pointer;
  padding: 14px 50px;
  border: none;
  color: #fff;
  background-color: #9ec759;
  border-radius: 16px;
  /* font */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22pt;
  line-height: 22pt;
}
</style>