<template>
  <div class="base-timer" :style="cssVars">
    <svg :viewBox="`0 0 ${2 * radius} ${2 * radius}`">
      <circle
        v-if="time_remaining === 0"
        fill="skyblue"
        :cx="radius"
        :cy="radius"
        :r="`${radius - 1}`"
      />
      <path v-else fill="#2172a3" :d="d" />
      <circle
        fill="none"
        stroke="#66c9f3"
        :stroke-width="stroke_width"
        :cx="radius"
        :cy="radius"
        :r="`${radius - 2}`"
      />
    </svg>
    <div class="text">
      {{ time_remaining }}
    </div>
  </div>
</template>
<script>
export default {
  name: "NewTimerCountdown",
  props: {
    timer_prop: {
      type: [Number, String],
      required: true,
    },
    timer_stroke_width: {
      type: Number,
      default: 4,
    },
    timer_radius: {
      type: Number,
      default: 77,
    },
    label_font_size: {
      type: Number,
      default: 35,
    },
    label_line_height: {
      type: Number,
      default: 35,
    },
  },
  data() {
    return {
      radius: this.timer_radius,
      time_remaining: this.timer_prop,
      initial_timer_prop: this.timer_prop,
      stroke_width: this.timer_stroke_width,
      d: `M ${this.timer_radius - 0.001} 0 A ${this.timer_radius} ${
        this.timer_radius
      } 0 1 0 ${this.timer_radius} 0 L ${this.timer_radius} ${
        this.timer_radius
      } L ${this.timer_radius - 0.001} 0`,
    };
  },
  computed: {
    cssVars() {
      return {
        "--timer-width": this.radius * 2 + "px",
        "--timer-height": this.radius * 2 + "px",
        "--label-font-size": this.label_font_size + "px",
        "--label-line-height": this.label_line_height + "px",
      };
    },
  },
  methods: {
    polarToCartesian(centerX, centerY, radius, angleInDegrees) {
      var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
      var y = centerY + radius * Math.sin(angleInRadians);
      var x = centerX + radius * Math.cos(angleInRadians);

      return {
        x: x,
        y: y,
      };
    },

    describeArc(x, y, radius, startAngle, endAngle) {
      var start = this.polarToCartesian(x, y, radius, endAngle);
      var end = this.polarToCartesian(x, y, radius, startAngle);

      var arcSweep = endAngle - startAngle <= 180 ? "0" : "1";

      var d = [
        "M",
        start.x,
        start.y,
        "A",
        radius,
        radius,
        0,
        arcSweep,
        0,
        end.x,
        end.y,
        "L",
        x,
        y,
        "L",
        start.x,
        start.y,
      ].join(" ");

      return d;
    },
  },
  watch: {
    timer_prop: {
      handler(newTime) {
        this.time_remaining = newTime;
        this.d = this.describeArc(
          this.radius,
          this.radius,
          this.radius,
          0,
          (newTime / this.initial_timer_prop) * 360
        );
      },
    },
  },
};
</script>
<style scoped lang="scss">
.base-timer {
  position: relative;
  width: var(--timer-width);
  height: var(--timer-height);
}
.base-timer svg {
  width: var(--timer-width);
  height: var(--timer-height);
}
.base-timer .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* font */
  color: #fff;
  font-family: "Quicksand";
  font-size: var(--label-font-size);
  font-weight: bold;
  line-height: var(--label-line-height);
}
</style>