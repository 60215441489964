<template>
  <div class="users_rank">
    <div class="quiz_title_wrapper">
      <QuizzTitle :width="292" :height="223" />
    </div>
    <div class="results_container">
      <div class="congrats_title">
        <h2>Bravo !</h2>
        <div class="lottie_wrapper">
          <LottieAnimation
            path="./lottie/fish_bubble.json"
            :loop="true"
            :autoPlay="true"
            :speed="1"
            :width="70"
            :height="140"
          />
        </div>
      </div>
      <div class="ranks_container">
        <div class="session_rank_col">
          <h3>Classement de la partie</h3>
          <div class="players">
            <div
              v-for="(user, id) in session_rank"
              :key="id"
              :class="'player player_' + id"
            >
              <div class="position">{{ id < 10 ? "0" : "" }}{{ id + 1 }}</div>
              <div class="name">
                <strong> {{ user.name }}</strong> - {{ user.score }} pts
              </div>
            </div>
          </div>
        </div>
        <div class="general_rank_col">
          <h3>Classement général</h3>
          <div class="players">
            <div
              v-for="(user, id) in general_rank"
              :key="id"
              :class="'player player_' + id"
            >
              <div class="position">{{ id < 10 ? "0" : "" }}{{ id + 1 }}</div>
              <div class="name">
                <strong> {{ user.name }}</strong> - {{ user.score }} pts
              </div>
            </div>
          </div>
        </div>
      </div>
      <button type="button" @click="replayAGame">Rejouer !</button>
    </div>
  </div>
</template>
<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import QuizzTitle from "../utils/QuizzTitle.vue";
export default {
  name: "UsersRank",
  components: {
    QuizzTitle,
    LottieAnimation,
  },
  props: ["session_rank", "general_rank"],
  methods: {
    replayAGame() {
      this.$emit("replayGame");
    },
  },
};
</script>
<style scoped>
.quiz_title_wrapper {
  text-align: left;
  margin: 62px 0 0 79px;
}

.users_rank .results_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.users_rank .results_container .congrats_title {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  column-gap: 16.5px;
}
.users_rank .results_container .congrats_title h2 {
  margin: 0;
  text-transform: uppercase;
  /* font */
  font-family: "Quicksand";
  font-weight: 700;
  font-size: 50pt;
  line-height: 50pt;
}
.users_rank .results_container .congrats_title .lottie_wrapper > div {
  margin: 0 !important;
}

.users_rank .ranks_container {
  display: flex;
  justify-content: center;
  column-gap: 371px;
}
.users_rank .ranks_container .session_rank_col {
  width: 713px;
  text-align: start;
}

.users_rank .ranks_container .session_rank_col h3 {
  margin-top: 0;
  margin-bottom: 40px;
  text-transform: uppercase;
  /* font */
  font-family: "QuickSand";
  font-weight: 700;
  font-size: 20pt;
  line-height: 20pt;
}
.users_rank .ranks_container .session_rank_col .players {
  margin-bottom: 0;
  max-height: 355px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: flex-start;
  row-gap: 20px;
}

.users_rank .ranks_container .general_rank_col {
  width: 348px;
  text-align: start;
}
.users_rank .ranks_container .general_rank_col h3 {
  margin-top: 0;
  margin-bottom: 40px;
  text-transform: uppercase;
  /* font */
  font-family: "QuickSand";
  font-weight: 700;
  font-size: 20pt;
  line-height: 20pt;
}
.users_rank .ranks_container .general_rank_col .players {
  margin-bottom: 43px;
  max-height: 355px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  justify-items: flex-start;
  align-items: flex-start;
  row-gap: 20px;
}
.users_rank .ranks_container .players .player {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 35px;
}
.users_rank .ranks_container .players .player .position {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #00599c;
  border-radius: 50%;
  /* font */
  font-family: "Poppins";
  font-weight: 600;
  font-size: 1.75em;
  line-height: 42px;
}
.users_rank .ranks_container .players .player .name {
  /* font */
  font-family: "Quicksand";
  font-size: 20pt;
  font-weight: 500;
  line-height: 20pt;
}
.users_rank .ranks_container .players .player.player_0 .position {
  color: #fff;
  background-color: #90cff2;
}
.users_rank .ranks_container .players .player.player_0 .name {
  color: #90cff2;
}
.users_rank .ranks_container .players .player.player_1 .position {
  color: #fff;
  background-color: #e62a1f;
}
.users_rank .ranks_container .players .player.player_1 .name {
  color: #e62a1f;
}
.users_rank .ranks_container .players .player.player_2 .position {
  color: #fff;
  background-color: #e6b319;
}
.users_rank .ranks_container .players .player.player_2 .name {
  color: #e6b319;
}
.users_rank .results_container button {
  cursor: pointer;
  padding: 20px 30px;
  border: none;
  color: #fff;
  background-color: #9ec759;
  border-radius: 16px;
  /* font */
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 30pt;
  line-height: 30pt;
}
</style>